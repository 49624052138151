.ctm-home-page {
  .list-wapper {
    width: 100%;
    max-width: 500px;
    box-shadow: 4px 4px 10px #888888;
    padding: 20px;
    border-radius: 5px;
    max-height: 700px;
    height: 100%;
    overflow: auto;

    .student-card {
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;

      &:hover {
        background-color: azure;
      }
    }

    .student-name {
      font-size: 16px;
    }
  }
}