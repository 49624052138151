.login-form {}

.ctn-form-login {
  width: 100%;
  max-width: 500px;
  background: #59626eb3;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 30px;

  div {
    white-space: nowrap;
  }

  .sp-forgot-pwd {
    color: aqua;
    cursor: pointer;
  }

  .ant-form-item-control-input-content:has(button) {
    display: flex;
    justify-content: center;

    button {
      text-align: center;
      display: flex;
      align-items: center;
    }
  }

  .ant-form-item-control-input-content:has(a) {
    display: flex;
    justify-content: center;
  }
}

.gradient-7-color {
  background: linear-gradient(to top,
      #00AC9F,
      #FCFF07,
    );
  -webkit-background-clip: text;
  color: transparent;
}