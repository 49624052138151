.right-side {
  border: 1px solid #dadada;
  border-radius: 5px;
  box-shadow: 4px 4px 10px #888888;
  width: 100%;
  max-width: 700px;

  .address {
    width: 100%;
  }
}